import React, { Component, useEffect } from "react";
//import Svgsprite from "./svgsprite";
import styles from "../css/orderform.module.scss";
import formcss from "../css/form.module.scss";
import Modal from "react-modal";
import modalcss from "../css/modal.module.scss";

class Orderform extends Component {
  constructor(props) {
    super(props);
    this.hiddenIframe = React.createRef();
    this.state = {
      sensorsarray: this.selectSource(),
      value: "",
      formStepName: "stepOne",
      setsNeeded: this.setsOnStart(),
      isFormSubmitted: "no",
      discount: 1,
    };

    this.handleChangeIncludePrice = this.handleChangeIncludePrice.bind(this);
    this.handleChangeCount = this.handleChangeCount.bind(this);
    this.handleCountEncrement = this.handleCountEncrement.bind(this);
    this.changeFormSteps = this.changeFormSteps.bind(this);
    this.handleChangeSetsCount = this.handleChangeSetsCount.bind(this);
    this.handleSetsEncrement = this.handleSetsEncrement.bind(this);
    this.submitDone = this.submitDone.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  setsOnStart() {
    if (this.props.typeCharter) {
      return 250;
    } else {
      return 1;
    }
  }

  selectSource() {
    const privateData = [
      {
        id: 1,
        name: "BoatGOD",
        count: 1,
        iscountvisible: false,
        price: 195,
        enabled: true,
        group: "god",
      },

      {
        id: 2,
        name: "Wifi + 4G",
        count: 1,
        iscountvisible: false,
        price: 45,
        enabled: false,
        group: "base",
      },
      {
        id: 3,
        name: "UPS",
        count: 1,
        price: 25,
        iscountvisible: false,
        enabled: false,
        group: "base",
      },
      {
        id: 4,
        name: "Anti-theft module, preorder",
        count: 1,
        price: 99,
        iscountvisible: false,
        enabled: false,
        group: "base",
      },
      {
        id: 5,
        name: "Battery voltage and temperature",
        count: 1,
        price: 29,
        iscountvisible: true,
        enabled: false,
        group: "optional",
      },
      {
        id: 6,
        name: "Engine RPM, temperature and leak",
        count: 1,
        price: 39,
        iscountvisible: true,
        enabled: false,
        group: "optional",
      },
      {
        id: 7,
        name: "Tank level and leak",
        count: 1,
        price: 39,
        iscountvisible: true,
        enabled: false,
        group: "optional",
      },
    ];
    const charterData = [
      {
        id: 1,
        name: "BoatGOD",
        count: 1,
        iscountvisible: false,
        price: 195,
        enabled: true,
        group: "god",
      },

      {
        id: 2,
        name: "Wifi + 4G",
        count: 1,
        iscountvisible: false,
        price: 45,
        enabled: true,
        group: "base",
      },
      {
        id: 3,
        name: "UPS",
        count: 1,
        price: 25,
        iscountvisible: false,
        enabled: true,
        group: "base",
      },
      {
        id: 4,
        name: "Anti-theft module, preorder",
        count: 1,
        price: 99,
        iscountvisible: false,
        enabled: true,
        group: "base",
      },
      {
        id: 5,
        name: "Battery voltage and temperature",
        count: 1,
        price: 29,
        iscountvisible: true,
        enabled: true,
        group: "optional",
      },
      {
        id: 6,
        name: "Engine RPM, temperature and leak",
        count: 1,
        price: 39,
        iscountvisible: true,
        enabled: true,
        group: "optional",
      },
      {
        id: 7,
        name: "Tank level and leak",
        count: 1,
        price: 39,
        iscountvisible: true,
        enabled: true,
        group: "optional",
      },
    ];

    if (this.props.sourceDataCharter) {
      return privateData;
    } else {
      return charterData;
    }
  }

  calcSum() {
    const allsensorsstate = this.state.sensorsarray;
    const priceSum = allsensorsstate
      .filter((row) => row.enabled === true)
      .reduce(
        (total, currentValue) =>
          (total = total + currentValue.price * Number(currentValue.count)),
        0
      );

    let discount = this.state.discount;

    let sets = this.props.typeCharter ? 1 : this.state.setsNeeded;

    let finalPrice = priceSum * sets * discount;

    if (finalPrice !== 0) {
      return "€" + Math.round(finalPrice);
    } else return "special offer!";
  }

  handleChangeIncludePrice = (id) => (evt) => {
    let newsensorsarray = this.state.sensorsarray.map((row) => {
      if (id !== row.id) return row;
      else {
        const updatedVal = !row.enabled;
        return { ...row, enabled: updatedVal };
      }
    });

    this.setState({ sensorsarray: newsensorsarray });
  };

  handleChangeCount = (id) => (evt) => {
    const re = /^[0-9\b]+$/;

    if (evt.target.value === "" || re.test(evt.target.value)) {
      let newsensorsarray = this.state.sensorsarray.map((item) => {
        if (id !== item.id) return item;
        else return { ...item, count: evt.target.value };
      });

      this.setState({ sensorsarray: newsensorsarray });
    }
  };

  handleChangeSetsCount = (evt) => {
    var CurrentVal = evt.target.value;
    const re = /^[0-9\b]+$/;

    if (CurrentVal === "" || re.test(CurrentVal)) {
      this.setState(
        {
          setsNeeded: CurrentVal,
        },
        this.calcDiscount
      );
    }
  };

  handleCountEncrement = (id, type) => (evt) => {
    let newsensorsarray = this.state.sensorsarray.map((row) => {
      if (type === "plus" && id === row.id) {
        const updatedVal = Number(row.count) + 1;
        return { ...row, count: updatedVal };
      }
      if (type === "minus" && id === row.id && row.count > 1) {
        const updatedVal = Number(row.count) - 1;
        return { ...row, count: updatedVal };
      } else return row;
    });

    this.setState({ sensorsarray: newsensorsarray });
  };

  handleSetsEncrement = (type, prevState) => (evt) => {
    if (type === "plus") {
      if (this.props.typeCharter) {
        this.setState((prevState) => {
          return { setsNeeded: Number(prevState.setsNeeded) + 50 };
        }, this.calcDiscount);
      } else {
        this.setState((prevState) => {
          return { setsNeeded: Number(prevState.setsNeeded) + 1 };
        }, this.calcDiscount);
      }
    }
    if (type === "minus" && this.state.setsNeeded > 1) {
      if (this.props.typeCharter) {
        this.setState((prevState) => {
          return { setsNeeded: Number(prevState.setsNeeded) - 50 };
        }, this.calcDiscount);
      } else {
        this.setState((prevState) => {
          return { setsNeeded: Number(prevState.setsNeeded) - 1 };
        }, this.calcDiscount);
      }
    }
  };

  calcDiscount() {
    let currentSets = this.state.setsNeeded;

    if (currentSets > 250) {
      this.setState({
        discount: 0,
      });
    } else {
      this.setState({
        discount: 1,
      });
    }
  }

  DeviceItem = (props) => {
    const device = props.device;
    const mykey = props.inputkey;
    let sensorprice = device.count * device.price;

    return (
      <section className={formcss.formrowFlex}>
        <div className={(styles.item, styles.item_name)}>
          <label>
            <input
              onChange={this.handleChangeIncludePrice(device.id)}
              checked={device.enabled}
              name={device.id}
              type="checkbox"
            />
            {device.name}
          </label>
        </div>
        {device.iscountvisible && (
          <div className={(styles.item, styles.item_countsetter)}>
            <button
              className={formcss.button}
              type="button"
              onClick={this.handleCountEncrement(device.id, "minus")}
            >
              -
            </button>
            <input
              value={device.count}
              id={device.count}
              name={device.name}
              onChange={this.handleChangeCount(device.id)}
              type="text"
              key={mykey}
            />
            <button
              className={formcss.button}
              type="button"
              onClick={this.handleCountEncrement(device.id, "plus")}
            >
              +
            </button>
          </div>
        )}
        <div className={(styles.item, styles.item_price)}>€ {sensorprice}</div>
      </section>
    );
  };

  SensorsList = (props) => {
    const DeviceItem = this.DeviceItem;
    const group = props.group;
    const sensors = props.devicelist;
    const items = sensors
      .filter((row) => row.group === `${group}`)
      .map((device, i) => {
        return (
          <DeviceItem key={device.id} inputkey={device.id} device={device} />
        );
      });
    return <div className={formcss.rowgroup}>{items}</div>;
  };

  ViewCurrentOrderList = (props) => {
    const items = this.state.sensorsarray
      .filter((row) => row.enabled === true)
      .map((item, i) => {
        return (
          <div key={item.id} className={formcss.formrowFlex}>
            <div className={(styles.item, styles.item_name)}>{item.name}</div>
            <div className={styles.item}>x{item.count}</div>
          </div>
        );
      });
    return <div className={styles.orderList}>{items} </div>;
  };

  changeFormSteps = (stepName) => (evt) => {
    this.setState({
      formStepName: `${stepName}`,
    });
  };

  FormButton = (props) => {
    let buttonText = props.buttonText;
    let buttonType = props.buttonType;
    let disabled = props.disabled;
    let stepName = props.stepName;
    let buttonClass = props.buttonClass;

    return (
      <button
        onClick={this.changeFormSteps(stepName)}
        className={buttonClass}
        type={buttonType}
        disabled={disabled}
      >
        {buttonText}
      </button>
    );
  };

  OrderSetsView = () => {
    return (
      <section className={formcss.formrowFlex}>
        <div className={(styles.item, styles.item_countsetter)}>
          <button
            className={formcss.button}
            type="button"
            onClick={this.handleSetsEncrement("minus")}
          >
            -
          </button>
          <input
            value={this.state.setsNeeded}
            onChange={this.handleChangeSetsCount}
            type="text"
            name="setsNeeded"
            id="setsNeeded"
            key="sets"
          />
          <button
            className={formcss.button}
            type="button"
            onClick={this.handleSetsEncrement("plus")}
          >
            +
          </button>
        </div>
      </section>
    );
  };

  submitDone() {
    this.setState((state) => ({
      isFormSubmitted: "loading",
    }));
    this.hiddenIframe.current.onload = () => {
      this.setState((state) => ({
        isFormSubmitted: "yes",
        sensorsarray: this.selectSource(),
        formStepName: "stepOne",
        setsNeeded: this.setsOnStart(),
      }));
    };
  }

  handleCloseModal() {
    this.setState({
      isFormSubmitted: `no`,
    });
  }

  render() {
    let OrderList = this.ViewCurrentOrderList;
    let FormButton = this.FormButton;
    let SensorsList = this.SensorsList;
    let OrderSetsView = this.OrderSetsView;

    let button = "Send request";

    if (this.state.isFormSubmitted === "no") {
      console.log("not_submited");
    }
    if (this.state.isFormSubmitted === "loading") {
      console.log("loading now");
      button = "Wait, sending...";
    }
    if (this.state.isFormSubmitted === "yes") {
      console.log("submit done!");
    }

    return (
      <>
        <Modal
          shouldFocusAfterRender={false}
          closeOnEscape={true}
          isOpen={this.state.isFormSubmitted === "yes"}
          //isOpen={true}
          onRequestClose={this.handleCloseModal}
          className={modalcss.modal}
          overlayClassName={modalcss.overlay}
        >
          <div className="textcontent">
            <h3>Thank you for your request!</h3>
            <p>
              We will carefully review your request. Usually the response time
              is 5 business days.
            </p>
            <p>
              <button
                className={formcss.button}
                onClick={this.handleCloseModal}
                type="button"
              >
                Close
              </button>
            </p>
          </div>
        </Modal>
        <form
          method="post"
          target="hiddenFrame"
          action="https://www.flexyform.com/f/f0ebfac5ce0046f0d1a6625de133fc3015b0332b"
          //action="/"
          onSubmit={this.submitDone}
        >
          <section data-side="order" className="contentbox wrap --fullw">
            <div className="textcontent">
              <h2>
                Your order <span>configuration</span>
              </h2>

              <section
                className={
                  this.state.formStepName === "stepOne" ? "" : styles.hidden
                }
              >
                <h4>Required system module with NMEA + 2G</h4>
                <SensorsList group="god" devicelist={this.state.sensorsarray} />
                <h4>Additional options to the base module</h4>
                <SensorsList
                  group="base"
                  devicelist={this.state.sensorsarray}
                />
                <h4>Sensors available for installation</h4>
                <SensorsList
                  group="optional"
                  devicelist={this.state.sensorsarray}
                />
              </section>

              <section
                className={
                  this.state.formStepName === "stepTwo" ? "" : styles.hidden
                }
              >
                <input
                  type="hidden"
                  name="Order type"
                  value={
                    this.props.typeCharter
                      ? "Charter order"
                      : "Individual order"
                  }
                />
                <input
                  type="hidden"
                  name="Total price"
                  value={this.calcSum()}
                />
                <div className={formcss.formrow}>
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" />
                </div>
                <div className={formcss.formrow}>
                  <label htmlFor="email">Email*</label>
                  <input type="email" name="email" id="email" required />
                </div>
                <div className={formcss.formrow}>
                  <label htmlFor="phone">Phone</label>
                  <input type="text" name="phone" id="phone" />
                </div>
                <div className={formcss.formrow}>
                  <label htmlFor="addressCountry">Country</label>
                  <select name="addressCountry">
                    <option value="" selected disabled hidden>
                      Select shipping country
                    </option>
                    <option value="Other">Other</option>
                    <option value="GB">United Kingdom</option>
                    <option value="AL">Albania</option>
                    <option value="AD">Andorra</option>
                    <option value="AT">Austria</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BG">Bulgaria</option>
                    <option value="HR">Croatia (Hrvatska)</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="FR">France</option>
                    <option value="GI">Gibraltar</option>
                    <option value="DE">Germany</option>
                    <option value="GR">Greece</option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HU">Hungary</option>
                    <option value="IT">Italy</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MK">Macedonia</option>
                    <option value="MT">Malta</option>
                    <option value="MD">Moldova</option>
                    <option value="MC">Monaco</option>
                    <option value="ME">Montenegro</option>
                    <option value="NL">Netherlands</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="RO">Romania</option>
                    <option value="SM">San Marino</option>
                    <option value="RS">Serbia</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="ES">Spain</option>
                    <option value="UA">Ukraine</option>
                    <option value="DK">Denmark</option>
                    <option value="EE">Estonia</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FI">Finland</option>
                    <option value="GL">Greenland</option>
                    <option value="IS">Iceland</option>
                    <option value="IE">Ireland</option>
                    <option value="LV">Latvia</option>
                    <option value="LT">Lithuania</option>
                    <option value="NO">Norway</option>
                    <option value="SJ">Svalbard and Jan Mayen Islands</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="TR">Turkey</option>
                  </select>
                </div>
                <div className={formcss.formrow}>
                  <label htmlFor="message">Message</label>
                  <textarea name="message" id="message" rows="5" />
                </div>
              </section>
            </div>

            <div className={("videocontent", styles.formTotals)}>
              <section className={styles.totalTop}>
                {this.props.typeCharter ? (
                  <h3>Price for 1 set: {this.calcSum()}</h3>
                ) : (
                  <h3>Total amount: {this.calcSum()}</h3>
                )}

                <section>
                  <OrderList />
                  <div className={styles.editbutton}>
                    {this.state.formStepName === "stepTwo" && (
                      <FormButton
                        buttonText="Edit configuration"
                        buttonType="button"
                        stepName="stepOne"
                        buttonClass={formcss.buttonlink}
                      />
                    )}
                  </div>
                </section>

                <h4>Sets to purchase:</h4>
                <OrderSetsView />
              </section>

              <section className={styles.totalBottom}>
                {this.state.formStepName === "stepOne" && (
                  <FormButton
                    buttonClass={formcss.fullbutton}
                    buttonText="Save and proceed"
                    buttonType="button"
                    stepName="stepTwo"
                  />
                )}
                {this.state.formStepName === "stepTwo" && (
                  <FormButton
                    buttonClass={formcss.fullbutton}
                    buttonText={button}
                    buttonType="submit"
                    stepName="stepTwo"
                    disabled={
                      this.state.isFormSubmitted === "loading" && "disabled"
                    }
                  />
                )}
              </section>
            </div>
          </section>
        </form>
        <iframe
          ref={this.hiddenIframe}
          title="hiddenframe"
          name="hiddenFrame"
          src="about:blank"
          style={{ display: "none" }}
        ></iframe>
      </>
    );
  }
}

export default Orderform;
