import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Minislider from "../components/minislider";
import scheme from "../images/svg/scheme.svg";
import pricetable from "../images/svg/pricetable.svg";
import Svgsprite from "../components/svgsprite";
import Youtube from "../components/youtube";
import Orderform from "../components/orderform";
import styles from "../css/charters.module.scss";

const IndexPage = () => (
  <Layout>
    <SEO title="For Charters" />
    <section data-side="boatGod" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          Our solution <span>for charter companies</span>
        </h2>
        <p>
          Our solution for charter companies is based on the BoatGod module,
          which is a full-fledged yachting computer. It allows to collect,
          process, analyse and deliver data from the boat system, both through
          the NMEA interface and additional sensors manufactured by BoatPilot.
        </p>

        <p>
          This allows the charter company to have full control over the state of
          the entire fleet, regardless of its size. Obtaining statistics on
          equipment wear and service intervals, helping to efficiently build up
          stock reserves of spare parts and an efficient distribution. In
          addition, the artificial intelligence system analyzes all the
          skipper’s actions and so draws up his profile. This means, that the
          charter company can save a significant amount of time during the
          check-out routine, receiving notifications about the need for
          additional or more thorough check of specific ship systems in abnormal
          operation, helping decide to keep the deposit or not. It also provides
          handy documentary evidence of violations by the skipper supported by
          data from objective control devices.
        </p>

        <p>
          Such an approach allows for a significant optimization of insurance
          premiums, especially considering the guaranteed theft protection
          provided by the BlackOut service.
        </p>

        <p>
          We also supply data on violation of the rules of navigation -
          violation of the rules of the COLREGS, violation of the speed limit,
          passage to restricted areas, dangerous proximity with other vessels
          and/or approach to the shallows.
        </p>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "charter_1.png",
            "charter_2.png",
            "charter_3.png",
            "charter_4.png",
            "charter_5.jpg",
            "charter_6.jpg",
            "charter_7.jpg",
            "charter_8.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube
          tubeid="vHFx6zIssh0"
          posterimagename="tubeposter_charter.jpg"
        />
      </div>
    </section>
    <section
      data-side="scheme"
      className="contentbox  wrap --fullw --dark --novideo --killtoppadding"
    >
      <div className="textcontent">
        <Svgsprite svgclass="scheme" name={scheme.id} vbox={scheme.viewBox} />
      </div>
    </section>
    <section data-side="why" className="contentbox wrap --fullw --novideo">
      <div className="textcontent --max80">
        <h2>
          Why Boatpilot <span>what problems does it solve</span>
        </h2>
        <h3>Always in touch</h3>
        <p>
          The BoatGod head module is equipped with GPS, WIFI, 4G interfaces as
          well as the iridium satellite interface as an offshore option.
          Theprocessor’s power and its memory capacity allows for full logging
          and analysis of the data for several weeks. The module is equally
          equipped with a built-in UPS in case of a pirate attack or a power
          disconnection or failure.
        </p>
        <h3>Telemetry</h3>
        <p>
          Aside from the accurate positioning, speed and direction it also
          calculates and defines depths, usage of the sails and motor, the speed
          and direction of the wind and errors on or damage to the NMEA devices.
        </p>
        <h3>Data cloud & analytics</h3>
        <p>
          The module is responsible for sending the collected analytical
          information to the BoatPilot cloud, so providing it to the charter
          company. In addition, it provides the skipper with the ability to
          receive data from on-board systems directly on his chartplotter via
          the WiFi Nmea multiplexer. The developed alert system warns the
          captain of the risk of emergency situations based off the monitoring
          of all on-board systems, the actions of the skipper and the situation
          around the boat - data provided by AIS.
        </p>

        <p>
          The system will also remind to recharge batteries, drain black tanks,
          and replenish water supplies. It’s also able to identify problems in
          the operation of on-board equipment, including the motor (impeller,
          antifreeze leak, lack of oil). This can significantly reduce the risk
          of collisions, damage and improve sailing safety.
        </p>

        <h3>Support and feedback</h3>

        <p>
          And finally, the central module provides the charter company with the
          opportunity to organize a user support and feedback channel. The
          charter company gets the chance to receive requests from the skipper
          and equally to send him notifications, warnings and recommendations.
          For example, in the event of a storm warning, the operator may
          recommend that vessels head to a sheltered bay or a specific marina,
          where boat spaces for this company would stand reserved.
        </p>

        <p>
          The list of functions of the head module also includes WiFi APN with
          the ability to provide guests with Internet access services and
          centralized management of traffic packages.
        </p>
      </div>
    </section>
    <section
      data-side="sensors"
      className="contentbox wrap --fullw --dark --novideo"
    >
      <div className="textcontent --max80">
        <h2>
          Additional services <span> proprietary BoatPilot sensors</span>
        </h2>
        <p>
          As aside from the standard sensors, such as a gas leakage sensor, a
          sea water intake sensor, a PIR sensor, a burglary sensor (unauthorized
          access), BoatPilot also offers the following types of sensors and
          modules:
        </p>

        <h3>Battery sensor</h3>
        <p>
          Our sensor not only determines the battery level, but also its
          possible damage (bloating, lack of electrolyte) and the associated
          increase in temperature (spontaneous heating) of battery banks. Unlike
          any other solutions on the market, our sensor has the ability to
          automatically or manually remotely disconnect batteries. This allows
          to avoid the risk of spontaneous ignition of the batteries, prevents
          deep discharge and prolongs the bank life cycle.{" "}
        </p>
        <h3>Motor sensor</h3>
        <p>
          Motor sensor - ‘motor guard’. It not only determines the number of
          revolutions, allowing the charter company to identify violations in
          the operation of the motor (constant use at high or maximum RPM) but
          it also allows to identify problems associated with a lack of oil or
          antifreeze, as well as impeller.
        </p>
        <h3>Liquid level sensor for tanks</h3>
        <p>
          This sensor can be used on all types of marine tanks - water, fuel or
          black. The sensor is glued to the outside of the tank. It does not
          require immersion and therefore does not clog or fail. This sensor can
          issue warnings about exceeding or decreasing the permissible fluid
          level. It’s equipped with a wireless interface and an independent
          battery; therefore its installation does not require wiring and takes
          just several minutes.
        </p>
        <h3>BlackOut module</h3>
        <p>
          The module is designed to guarantee protection of the vessel from
          theft. Its work is based on fuel management. In cases where the
          BoatGod head module determines a possible pirate attack, the BlackOut
          module reduces the fuel supply to the engine, making the hijacking of
          the vessel impractical. In this limiting mode, the maximum RPM can be
          is limited to 500-700 (2 knots max speed). Important to note: the
          modules operate in a completely independent mode. They are not
          sensitive to just any pirate actions - to the turning off of the
          onboard power supply, turning off of the NMEA, interference or
          complete jamming of the GPS, GSM and/or iridium signals.
        </p>
      </div>
      <div className="minislider --upHeight">
        <Minislider
          slides="3"
          names={[
            "god_1.jpg",
            "god_2.jpg",
            "god_3.jpg",
            "god_4.jpg",
            "god_5.jpg",
            "god_6.jpg",
            "god_7.jpg",
          ]}
        />
      </div>
    </section>
    <section data-side="pricing" className="contentbox wrap --fullw  --novideo">
      <div className="textcontent" style={{ paddingRight: "0px" }}>
        <h2>
          Pricing <span> table</span>
        </h2>
        <div className={styles.mobileInfo}>
          <p>
            <b>Equipment</b>
          </p>
          <p>
            BoatGod nmea 2000 main module + 6 sensors for installation at two
            points (engine rpm, battery charge, temperature x2, leakage)
          </p>
          <p>
            <b>Cloud platform</b>
          </p>
          <p>
            Fleet manager's personal account with access to telemetry, alerts
            and analytics. Cost per month of use with a 3 year contract
          </p>
        </div>

        {/* <table className={styles.table}>
          <thead>
            <tr>
              <td className={styles.column}></td>
              <th>
                Basic <span>Price</span>
              </th>
              <th>
                20+ <span>boats</span>
              </th>
              <th>
                50+ <span>boats</span>
              </th>
              <th>
                100+ <span>boats</span>
              </th>
              <th>
                250+ <span>boats</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className={styles.column}>
                Equipment
                <p>
                  BoatGod nmea 2000 main module + 6 sensors for installation at
                  two points (engine rpm, battery charge, temperature x2,
                  leakage)
                </p>
              </th>
              <td>EUR 295</td>
              <td>EUR 245</td>
              <td>EUR 195</td>
              <td>EUR 95</td>
              <td>EUR 55</td>
            </tr>
            <tr>
              <th className={styles.column}>
                Cloud platform{" "}
                <p>
                  Fleet manager's personal account with access to telemetry,
                  alerts and analytics. Cost per month of use with a 3 year
                  contract
                </p>
              </th>
              <td>EUR 19.95/m</td>
              <td>EUR 15.95/m</td>
              <td>EUR 13.95/m</td>
              <td>EUR 10.95/m</td>
              <td>EUR 5.95/m</td>
            </tr>
          </tbody>
        </table> */}

        <table className={styles.table}>
          <thead>
            <tr>
              <td className={styles.column}></td>
              <th>
                Equipment
                <p>
                  BoatGod nmea 2000 main module + 6 sensors for installation at
                  two points (engine rpm, battery charge, temperature x2,
                  leakage).
                </p>
              </th>
              <th>
                Cloud platform
                <p>
                  Fleet manager's personal account with access to telemetry,
                  alerts and analytics. Cost per month of use with a 3 year
                  contract.
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                Basic <span>Price</span>
              </th>
              <td>EUR 295</td>
              <td>EUR 19.95/m</td>
            </tr>
            <tr>
              <th>
                20+ <span>boats</span>
              </th>
              <td>EUR 245</td>
              <td>EUR 15.95/m</td>
            </tr>
            <tr>
              <th>
                50+ <span>boats</span>
              </th>
              <td>EUR 195</td>
              <td>EUR 13.95/m</td>
            </tr>
            <tr>
              <th>
                100+ <span>boats</span>
              </th>
              <td>EUR 95</td>
              <td>EUR 10.95/m</td>
            </tr>
            <tr>
              <th>
                250+ <span>boats</span>
              </th>
              <td>EUR 55</td>
              <td>EUR 5.95/m</td>
            </tr>
          </tbody>
        </table>

        <h3>Additional optional sensors</h3>
        <ul className="list">
          <li>Anti-theft module (preorder): € 99</li>
          <li>UPS: € 25</li>
          <li>Tank level and leak: € 39</li>
        </ul>
      </div>
    </section>

    {/* <Orderform typeCharter={true} orderType="charter" /> */}
  </Layout>
);

export default IndexPage;
